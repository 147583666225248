var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TModal',{attrs:{"title":_vm.type == 'lading_bill' ? 'Select lading bill' : 'Select order',"show":_vm.show,"size":"xl"},on:{"update:show":function($event){return _vm.$emit('update:show', $event)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div')]},proxy:true}])},[_c('TTableAdvance',{staticClass:"table-custom",attrs:{"items":_vm.list,"fields":_vm.fieldC,"store":_vm.store,"resource":"","reloadable":""},on:{"click-clear-filter":_vm.clearFilter,"click-reload":_vm.fetch},scopedSlots:_vm._u([{key:"_",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TButtonAdd',{on:{"click":function($event){return _vm.select(item)}}})],1)]}},{key:"lading_bill_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TLink',{staticClass:"text-nowrap",attrs:{"to":((_vm.lodash.getReferenceLink('lading_bill',item.lading_bill_id )) + "&tab=1"),"content":item.lading_bill_id.toString()}})],1)]}},{key:"order_id",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.order)?_c('TLink',{staticClass:"text-nowrap",attrs:{"to":("/sale/" + (item.order.type_id) + "/" + (item.order_id)),"content":item.order_id}}):_vm._e()],1)]}},{key:"customer_id",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.lading_bill)?_c('TLink',{staticClass:"text-truncate-1",attrs:{"content":item.lading_bill.customer
              ? item.lading_bill.customer.name
              : item.lading_bill.customer_id}}):_vm._e(),(item.order)?_c('TLink',{staticClass:"text-truncate-1",attrs:{"content":item.order.customer
              ? item.order.customer.name
              : item.order.customer_id}}):_vm._e()],1)]}},{key:"currency_id",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('SMessageCurrency',{attrs:{"id":item.currency_id}})],1)]}},{key:"amount",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.amount,"currency":item.currency_id}})],1)]}},{key:"unsolved",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.unsolved,"currency":item.currency_id}})],1)]}},{key:"paid",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.paid,"currency":item.currency_id}})],1)]}},{key:"note",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.note,"italic":"","noTranslate":""}})],1)]}},{key:"created_at",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('TMessageDateTime',{attrs:{"content":item.created_at,"small":""}})],1)]}},{key:"lading_bill_id-filter",fn:function(){return [_c('TInputText',{attrs:{"placeholder":"Id","value":_vm.filter.lading_bill_id},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "lading_bill_id", $event)},_vm.filterChange]}})]},proxy:true},{key:"order_id-filter",fn:function(){return [_c('TInputText',{attrs:{"placeholder":"Order Id","value":_vm.filter.order_id},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "order_id", $event)},_vm.filterChange]}})]},proxy:true},{key:"customer_id-filter",fn:function(){return [_c('SSelectCustomer',{attrs:{"placeholder":"Customer Id","value":_vm.filter[
            _vm.type == 'lading_bill'
              ? 'ladingBill.customer_id'
              : 'order.customer_id'
          ],"prependAll":""},on:{"update:value":function($event){return _vm.$set(_vm.filter, 
            _vm.type == 'lading_bill'
              ? 'ladingBill.customer_id'
              : 'order.customer_id'
          , $event)},"change":_vm.filterChange}})]},proxy:true},{key:"currency_id-filter",fn:function(){return [_c('SSelectCurrency',{attrs:{"placeholder":"Currency Id","value":_vm.filter['currency_id']},on:{"update:value":function($event){return _vm.$set(_vm.filter, 'currency_id', $event)},"change":_vm.filterChange}})]},proxy:true},{key:"created_at-filter",fn:function(){return [_c('TInputDateTimeRange',{attrs:{"value":_vm.dateRange},on:{"update:value":[function($event){_vm.dateRange=$event},_vm.setCreatedAtFilter]}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }